.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App .MuiAppBar-colorPrimary {
    color: #85a2ad;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* hacky fix for demo, look into real way.. of removing fixed height from the grid*/
.react-grid-layout {
    flex-grow: 1;
    /*height: 100%!important;*/
    overflow-y: visible;
    overflow-x: visible;
}

.slick-dots li {
    margin: 0;
}

.slick-dots {
    bottom: 25px;
}

.slick-dots li button:before {
    font-size: 15px;
}

@media (max-width: 600px) {
    .slick-dots {
        bottom: 5px;
    }

    .slick-dots li button:before {
        font-size: 8px;
    }

    .slick-dots li {
        width: 15px;
    }
}

/*GENERAL PAGE STYLING*/
h1, h2, h3, h4, body {
    font-weight: 400;
    color: #413E40;
}

h1, h2, h3, h4, h5, h6 {
    text-transform: uppercase;
}

h1, h2, h3 {
    font-size: 34px;
    font-weight: 400;
    color: #444444;
    letter-spacing: 0.1em;
    margin: 0px

}

/*h2, h3 {*/
/*  letter-spacing: 0.1em;*/
/*}*/
h4 {
    font-size: 20px;
    letter-spacing: 0.1em;

}

h1, p {
    margin-top: 0px;
    margin-bottom: 0px;
}

.tcCopy p {
    margin-bottom: 15px;
}

.tcCopy {
    margin-bottom: 15px;
    line-height: 1.5;
}


.MuiTypography-body1 a:link,
.MuiTypography-body1 a:visited,
.MuiTypography-body1 a:hover,
.MuiTypography-body1 a:active {
    color: #413E40;
}

.headerContainer {
    width: 50%;
    /*background: blueviolet;*/
}


@media only screen and (max-width: 1920px) {
    .headerContainer {
        width: 60%;
        /*background: red*/
    }
}

@media only screen and (max-width: 1280px) {

    .headerContainer {
        width: 85%;
        /*background: green;*/
    }
}

@media only screen and (max-width: 960px) {

    .headerContainer {
        width: 95%;
        /*background: blue;*/

    }
}

@media only screen and (max-width: 600px) {
    .headerContainer {
        width: 100%;
        /*background: pink;*/

    }
}

/*REDMANS MEDIA QUERY*/
@media only screen and (max-width: 600px) {
    .body {
        font-size: 15px;
        /*background: red !important;*/

    }

    /*h1, h2, {*/
    /*  font-size: 26px !important;*/
    /*background: red !important;*/
    /*}*/
}

p:last-child {
    margin-top: 0px;
    margin-bottom: 0px;
}

.spacer {
    height: 60px;
}

.spacerNav {
    height: 64px;
}

.spacerMed {
    height: 30px;
}

.spacerSmall {
    height: 15px;
}

.spacerTiny {
    height: 5px;
}

.bgGray {
    background: #413E40;
}

.bgGreen {
    background: #99BFC2;
}
.bgRed {
        background: #BE4141;
}

.bgBlue {
    background-color: #85a2ad;
}

.bgWhite {
    background: #FFF;
}

.MuiDivider-root, .jpDivider {
    height: 10px;
    position: sticky
}

/*.leader {*/
/*  max-width: 70%;*/
/*}*/
/*.bgBlue h1, .bgGreen h1, .bgGray h1, .bgGreen h2, .bgGray h2, .bgBlue .body, .bgGreen .body, .bgGray .body {*/
/*  color: #FFF;*/
/*}*/

.bgBlue h1, .bgBlue h2, .bgBlue h3, .bgBlue h1, .bgBlue .body {
    color: #FFF;
}
.bgRed h1, .bgRed h2, .bgRed h3, .bgRed h4, .bgRed .body, .bgRed p {
    color: #FFF;
}

.bgGreen h1, .bgGreen h2, .bgGreen h3, .bgGreen h1, .bgGreen .body {
    color: #FFF;
}

.bgGray h1, .bgGray h2, .bgGray h3, .bgGray h1, .bgGray .body {
    color: #FFF;
}

.bgWhite .body, .bgWhite h1, .bgWhite h2, .bgWhite h3, .bgWhite h4 {
    color: #413E40;
}

/*BUTTONS*/
.MuiButton-sizeLarge .MuiButton-label {
    padding: 20px 60px 20px 60px;
    /*padding: 20px;*/
}

@media only screen and (max-width: 600px) {
    .MuiButton-sizeLarge .MuiButton-label {
        padding: 15px 30px 15px 30px;
        /*padding: 20px;*/
    }
}

.MuiButton-sizeSmall .MuiButton-label {
    padding: 10px 30px 10px 30px;
}

/*TO ME MIGRATED*/
/*.MuiButton-root {*/
/*  padding: 0px !important;*/
/*}*/
/*.hometile .MuiButton-label {*/
/*  font-size: 18px;*/
/*  color: #FFF;*/
/*  font-weight: 600;*/
/*  letter-spacing: 0.1em;*/
/*}*/
/*.jpTile .MuiButton-label, .white .MuiButton-label, .filled .MuiButton-label {*/
/*  padding: 20px 60px 20px 60px;*/
/*}*/
/*.MuiButton-outlined.sq  {*/
/*  width: 100px !important  ;*/
/*  height: 100px !important;*/
/*  border: 2px solid !important;*/
/*  border-color: #413E40 !important;*/
/*  border-radius: 8px !important;*/
/*  margin-right: 40px;*/


/*}*/
/*.sq .MuiButton-label {*/
/*    color: #413E40 !important;*/
/*}*/
/*.MuiButtonBase-root.filledSmall {*/
/*  border: none !important;*/
/*    background: #413E40 !important;*/

/*}*/
/*.filledSmall .MuiButton-label {*/
/*  padding: 0px !important;*/
/*  color: #FFF;*/
/*  font-weight: 600;*/
/*}*/

/*.MuiButton-label {*/
/*  font-size: 16px;*/
/*  padding: 20px 60px 20px 60px;*/
/*  color: #FFF;*/
/*  font-weight: 600;*/
/*}*/
/*.MuiButton-outlined {*/
/*  border: 2px solid !important;*/
/*}*/
/*.blue .MuiButton-outlined {*/
/*  border-color: #FFF !important;*/
/*}*/
/*.white .MuiButton-outlined {*/
/*  border-color: #413E40 !important;*/
/*  background: #413E40;*/
/*}*/
/*.MuiButton-outlined.filled {*/
/*  border-color: #413E40 !important;*/
/*  background: #413E40;*/
/*}*/

/*PRODUCT PREVIEW STYLING*/

.productPreview .MuiCard-root, .MuiPaper-root {
    background: #85A2AD
}

.productPreview .MuiPaper-rounded {
    border-radius: 0px;
}

.productPreview .MuiPaper-elevation1 {
    box-shadow: none;
}

.productPreview .MuiCardContent-root {
    padding: 20px;
}

.productPreview .MuiCardContent-root {
    color: #FFF
}

.productPreview .price {
    font-weight: 600;
}

/* PRODUCT EDIT*/
.squareButtons .MuiButton-label {
    padding: 20px;
    font-size: 14px;
}

@media only screen and (max-width: 600px) {

    .squareButtons .MuiButton-containedSizeLarge {
        width: 30px !important;
        height: 50px !important;
    }

    .squareButtons .MuiButton-label {
        padding: 10px;
        font-size: 14px;
    }
}


/*MY JIGPAWS*/
/*gerry*/
.uppy-DragDrop-browse {
    color: #fff !important;
    font-size: 72px;
    font-weight: 100
}

.uppy-DragDrop-container {
    font-family: 'Facundo', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
}

.uppy-DragDrop-container {
    border-radius: 0px !important;
}

.fixDragDrop > div {
    height: 100%;
}


/*HOMEPAGE*/
.jpBottomLineMob {
    /*background: red;*/
    border-bottom: 1px Solid;
    border-bottom-color: #413E40;
}

.jpTile {
    padding: 60px;
}

.jpTile h2, .jpTile p, .jpTile .body {
    text-wrap: balance;
}

.jpTile h2, .jpTile H1 {
    margin: 0px;
    line-height: 34px;
}

.jpTileInner {
    padding-left: 15%;
    padding-right: 15%;
}

.jpProductInner .body {
    font-size: 18px;
}

.jPDynamic {
    white-space: pre-line;
}

@media only screen and (max-width: 1920px) {

    .jpTileInner {
        padding-left: 7%;
        padding-right: 7%;

    }
}

@media only screen and (max-width: 1280px) {
    .jpTile {
        padding: 0px
    }

    .jpTileInner {
        padding-left: 7%;
        padding-right: 7%;
        font-size: 15px;
    }

    .jpTileInner h2 {
        font-size: 24px;
    }

    .jpProductInner p {
        font-size: 16px;
    }

    .jpProductInner .spacerSmall {
        height: 10px
    }

    .jpProductInner .spacerMed {
        height: 20px
    }

    .MuiTypography-h6 {
        font-size: 17px !important;
        padding-left: 10px !important;
        padding-right: 10px !important;;

    }
}


@media only screen and (max-width: 960px) {
    .jpTile {
        padding: 28px
    }

    .jpTileInner {
        padding-left: 5%;
        padding-right: 5%;

    }

    .jpTileInner h2 {
        font-size: 34px;
    }

    .jpProductInner p {
        font-size: 18px;
    }

    .jpProductInner .spacerSmall {
        height: 15px
    }

    .jpProductInner .spacerMed {
        height: 30px
    }

}

@media only screen and (max-width: 600px) {
    .jpTile {
        padding: 30px

    }

    .jpTileInner {
        /*background: red !important;*/
        padding-left: 0%;
        padding-right: 0%;

    }
}


.white h2, .white {
    color: #413E40 !important;
}

.slick-slider {
    height: 100%;
}

.slick-slide img {
    height: 100%;
}

/*PRODUCT INFO*/
.jpProductInner h2 {
    line-height: 34px;
}

.jpProductInner a:hover {
    text-decoration: none;
}


.jpLine {
    border-bottom: 10px solid !important;
    border-bottom-color: #85A2AD !important;
}

@media (max-width: 960px) {
    .jpLineMob {
        border-bottom: 10px solid !important;
        border-bottom-color: #85A2AD;
    }
}

/* PRODUCT CREATE PAGE */
.productInfoTop {
    width: 100%;
    text-align: left;
}

.productInfoMiddle {
    text-align: left;
    display: flex;
    align-items: center;
}

.productInfobottom {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: left;
}

@media (min-width: 960px) {
    .productInfoTop {
        height: 33%;
        width: 100%;
        text-align: left;
        padding-left: 30px;
    }

    .productInfoMiddle {
        height: 33%;
        text-align: left;
        padding-left: 30px;
        display: flex;
        align-items: center;
    }

    .productInfobottom {
        height: 34%;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: left;
        padding-left: 30px;
    }
}

.close {
    display: flex;
    flex-direction: row;
    height: 40px;
    align-content: flex-end;
}

.productInfo {
    height: 100%;
    padding: 0px 0px 0px 20px;
    /*display: flex;*/
    /*flex-direction: row;*/
    /*align-items: flex-start;*/
    /*justify-content: space-between;*/

}

.productInfoDetail {
    color: #FFF;
}

@media only screen and (max-width: 1280px) {
    .productInfoTop, .productInfoDetail {
        font-size: 15px;
    }

    .productInfoTop .productInfoDetail {
        margin-top: 15px;
    }
}

.productDetails {
    background: #85a2ad;
    padding-left: 30px;
    text-align: left;
    color: #FFF;
    padding-bottom: 30px;
}

.productDetails h2 {
    color: #FFF;
}

@media only screen and (max-width: 960px) {
    .productDetails h2 {
        font-size: 18px;
    }
}

.productQuantityCost {
    display: flex;
}

.productCost {
    margin-left: 30px;
    color: #413E40;
    font-weight: 600 !important;
}

.productInfoImage {
    padding: 0px !important;
}

.productInfobottom .MuiInputBase-input {
    color: #413E40;
}

/* .productEdit {
} */
/*MENUS*/

/* CART */
.cartItemRightContainer {
    height: 100%;
}

.cartPriceContainer {
    text-align: right;
}

/* .productFirstLoad {
    /*opacity: .5;*/
/* } */

.productFirstLoadContainer {
    background: #413E40;
}

.productFirstLoadIcon, .productFirstLoadOverlay {
    /*background: #413E40;*/
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;

}

.productFirstLoadOverlay {
    background: #413E40;
    opacity: 0.5;
}


@media (max-width: 960px) {
    .cartItemRightContainer {
        height: 200px;
        margin-top: 20px;
    }
}

.cartSubContainer {
    /*width: 81px;*/
    display: inline-block;
    /*border-bottom: 1px solid;*/
    /*border-bottom-color: #99BFC2;*/

}

.cartSubContainer:not(:first-child) {
    margin-left: 30px !important;
}


.cartDivider {
    border-bottom: 1px solid;
    border-bottom-color: #99BFC2;
    /*margin-left: 20px;*/
}

@media (max-width: 960px) {
    .cartItemContainer {
        padding: 0px 24px 0px 24px;
    }
}

@media (min-width: 960px) {
    .cartItemRightContainer {
        border-bottom: 1px solid;
        border-bottom-color: #99BFC2;

        /*margin-left: 20px;*/
    }
}

.borderTop {
    border-top: 1px solid;
    border-bottom-color: #99BFC2;
}

@media (max-width: 960px) {
    .cartBottomRow {
        margin-left: 0px;
    }

    .cartTotals {
        padding-right: 30px;
    }
}

.cartItem {
    padding: 0px !important;
}

.cartInfo {
    padding-left: 20px;
    height: 100%;
    position: relative;
}

@media (max-width: 960px) {
    .cartInfo {
        padding-left: 0px;
        height: 200px;
    }
}


.cartInfoTop {
    color: #413E40;
    /*display: flex;*/
    /*flex-direction: row;*/
    /*flex-wrap: nowrap;*/
    /*align-content: space-between;*/

}

.cartInfoBottom {
    position: absolute;
    bottom: 0px;
}

.cartInfoBottom .MuiButton-sizeSmall .MuiButton-label, .smallButtonOverride .MuiButton-sizeSmall .MuiButton-label {
    padding: 0px;
    font-size: 14px;
    color: #413E40;
}

.buttonContainerContainer {
    display: flex;
    align-items: center;
    padding-top: 10px
}

/*.buttonContainer {*/
/*    display: flex;*/
/*    justify-content: space-between;*/
/*}*/
.buttonContainer .MuiButton-sizeSmall .MuiButton-label, .smallButtonOverride .MuiButton-sizeSmall .MuiButton-label {
    padding: 5px 15px 0px 15px;
    font-size: 14px;
}

.cartInfoBottom .MuiButton-sizeSmall .MuiButton-label:hover, .smallButtonOverride .MuiButton-sizeSmall .MuiButton-label:hover {
    padding: 0px;
    font-size: 14px;
    color: #413E40;
    background: #FFF;
    text-decoration: underline;
}

.cartRemoveLink {
    position: absolute;
    bottom: 10px;
}

/* .cartInfoBottom {
    /*display: flex;*/
/*flex-direction: row;*/
/*align-items: flex-end;*/
/*  flex-wrap: wrap;*/
/*  justify-content: left;*/
/* } */


.detail {
    color: #413E40;
    font-size: 14px;
}

.cartPrice, .cartSub {
    font-weight: 600 !important;
    padding: 0px;
    color: #413E40;
}

.cartSub {
    font-size: 24px;

}

.cartTotals .cartPrice {
    font-size: 34px !important;
}

.discounts {
    color: #99BFC2
}

.cartInfo .MuiSelect-root {
    color: #413E40;
}

.cartTotals {
    text-align: right;
    font-size: 16px;
    color: #413E40;
    border-bottom: 1px solid;
    border-bottom-color: #99BFC2;
}

@media (max-width: 960px) {

}

.cartPadding {
    margin-left: 20px !important;
}

/*IMAGE PICKER*/
.pickerTitle {
    text-align: center;
}

.simple-dialog-title {
    font-size: 200px !important;
}

.checkoutFixedWidth .MuiButtonBase-root {
    width: 350px;
}

/*LOG IN*/
.formHolder {
    display: flex;
    align-items: center;
    justify-content: center;
}

.formBox {
    background: #99BFC2 !important;
    padding: 30px;
}

/*@media only screen and (max-width: 960px) {*/
/*    .formBox {*/
/*        padding: 0px;*/
/*    }*/
/*}*/


.formBox .formName {
    color: #413E40;
    text-transform: uppercase;
}

.formBox .MuiButton-tex {
    font-weight: 400;
    font-size: 18px;
    text-transform: none;
}

.formBox .formLink .MuiButton-label, .formBox .formLink, .formBox p, .formBox .MuiLink-root {
    color: #413E40;
    font-size: 14px !important;
}

.formBox .filled .MuiButton-label {
    text-transform: uppercase;
}

.formBox .MuiLink-root {
    text-decoration: underline;
    text-transform: none;
}

.formBox .MuiInputBase-input {
    background: #FFF;
}

.formBox .MuiFilledInput-underline:before {
    border-bottom: none;
}

.formBox .MuiFilledInput-underline:after {
    border-bottom: 2px solid #85a2ad;
}

.formBox .MuiInputBase-input {
    color: #413E40;
    border-top-left-radius: 0 !important; /* need to beat .MuiFilledInput-input:-webkit-autofill */
    border-top-right-radius: 0 !important; /* need to beat .MuiFilledInput-input:-webkit-autofill */
}

.formBoxWhite .MuiFilledInput-root {
    border-top-left-radius: 0 !important; /* need to beat .MuiFilledInput-input:-webkit-autofill */
    border-top-right-radius: 0 !important; /* need to beat .MuiFilledInput-input:-webkit-autofill */
    height: 64px;
}

.formBoxWhite {
    display: flex;
    /*height: 100%;*/
    /*align-items: left;*/
    justify-content: center;
    /*width: 70%  ;*/
}

.formBoxWhite .MuiButtonBase-root {
    /*background: red !important;*/
    padding: 0px;
    margin: 0px;
    height: 64px;
}

/* .formBox .MuiButton-label {
    /*color: #99BFC2;*/
/* } */

.formContainerWhite {
    display: flex;
    justify-content: center;
    /*background: pink !important;*/
    align-items: center;
}

.test {
    background: red !important;
}

.removeDiscount .MuiButtonBase-root {
    /*background: #413E40;*/
    /*background: red !important;*/
    width: 16px;
    height: 16px;
    margin-right: 10px;
}

.formBoxWhite .MuiFormControl-fullWidth {
    width: 48%;
}

@media only screen and (max-width: 600px) {
    .formBoxWhite .MuiFormControl-fullWidth {
        width: 57%;
    }
}

/*.formBox .filled .MuiButton-label {*/
/*  font-size: 16px;*/
/*  color: #FFF;*/
/*  font-weight: 600;*/
/*  letter-spacing: 0.1em;*/
/*}*/
/*.formBox .filled .MuiButton-label {*/
/*  padding: 20px 60px 20px 60px;*/
/*}*/
.formBox .MuiButton-contained {
    box-shadow: none !important;
}

.makeStyles-margin-24 {
    margin: 0px !important;
}

.formBox .MuiButton-root .filled {
    border-color: #413E40 !important;
    background: #413E40;
}

.MuiMenu-list {
    background: #85A2AD;
}

@media only screen and (max-width: 960px) {
    .mobileNone {
        display: none;
    }
}

@media only screen and (min-width: 960px) {
    .desktopNone {
        display: none;
    }
}

/* FIXES */

.slick-dots li button:before {
    color: white !important;
}

.MuiIconButton-root {
    background-color: #99BFC2 !important;
}

.jpIcon.MuiIconButton-root {
    background-color: #99BFC2;
    color: white;
    height: 48px;
    width: 48px;
    border-radius: 50%;
}

.MuiIconButton-edgeEnd {
    margin-right: 10px !important;
}

.MuiIconButton {
    color: #99BFC2 !important;
}

.MuiBadge-colorSecondary {
    color: #99BFC2 !important;
    background-color: #FFF !important;
    border: 2px solid;
}

.MuiIconButton-label {
    color: #FFF !important;
}

.MuiListItem-button:hover {
    background: #413E40 !important
}

/* FOOTER */
.FooterSocial {
    background-color: #413e40;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.paymentInfo > * {
    margin-left: 10px;
}

@media (max-width: 960px) {
    .FooterSocial {
        padding-left: 20px;
        padding-right: 20px;
        /*height: 25vh;*/
        /*minHeight: 0;*/
    }
}


/* LOGO */
.logoStyles {
    width: 391px;
}

@media only screen and (max-width: 600px) {
    .logoStyles {
        width: 90%;
    }
}


/* CREATE */
/* this fixes the alignment*/
.uppy-DragDrop-inner {
    padding: 0px !important;
}


/* ARROW REPLACE – to replace the upload arrow with a custom icon*/
/* .uppy-DragDrop-inner{
    content: url('/images/icons/uploadIcon.svg');
} */

.uppy-DragDrop-arrow {
    display: none !important;
}

/*PRODUCT DETAIL*/
.productDetailContainer {
    padding: 30px;
}

.productDetail {
    height: 100%;
    padding-left: 30px;
    /*background: red !important;*/
}

.App .pintura-editor {
    --color-primary: #85a2ad;
    --color-primary-dark: #413E40;
    --color-primary-text: #99BFC2;
    --color-secondary: #03a9f4;
    --color-secondary-dark: #046bbf;
    --font-family: Facundo, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
    --font-size: 16px;
}

.slick-track {
    display: flex;
    height: 100%;
}

.slick-list {
    height: 100%;
}

.slickText .slick-track .slick-slide {
    display: flex;
    height: auto;
    align-items: center;
    justify-content: center;
}

.scrollContainer {
    display: flex;
    height: auto;
    align-items: center;
    justify-content: center;
}

@media (min-width: 1920px) {
    .scrollContainer {

        padding-left: 10%;
        padding-right: 10%;
    }
}

@media (max-width: 1280px) {
    .scrollContainer {

        padding-left: 5%;
        padding-right: 5%;
    }
}


.marginBottomFix {
    margin-bottom: -5px;
}

/*.landingSlider {*/
/*    background: red;*/
/*}*/

/*NAV */
.logoSize {
    width: 106px;
}

@media (max-width: 600px) {
    .jpNav .MuiIconButton-root {
        padding: 10px;


        color: white;
        height: 44px;
        width: 44px;

    }
}

.header-top-padding {
    margin-top: 100px;
}

@media (max-width: 600px) {
    .header-top-padding {
        margin-top: 30px;
    }
}

@media (max-width: 800px) {
    .productInfoLarge {
        display: none
    }
}

@media (min-width: 800px) {
    .productInfoSmall {

        display: none

    }
}

.jpProductFooter {
    position: absolute;
    width: 100%;
    /*display: flex;*/
    /*flex-direction: row;*/
    /*align-items: flex-end;*/
    justify-content: center;
    bottom: 0px;
}

.jpProductCost {
    color: #413E40;
}

.productPriceDisclaimer {
    font-size: 16px;
    color: #413E40;
}

/*.productPriceDisclaimer:after {*/
/*  content: '*see exact currency conversion at checkout';*/
/*}*/
.bold {
    font-weight: 600;
}

.payment-message {
    margin-top: 20px;
    color: #FFF;
}

.MuiDialogActions-root .MuiButton-contained {
    width: 200px;
    height: 90px;
}

/*.BLOG */
.jpBlogImgHero {
    width: 100%;
    height: 400px;
    object-fit: cover;
}

.imgAlignBottom {
    object-position: 50% 100%;
}

.jpBlogItem p, .jpBlogSection h3, .jpBlogItem p, .jpBlogSection h2, .jpBlogSection h4, .jpBlogItem .imageHold {
    margin-bottom: 15px;
    text-align: left;
    color: #413e40;
}

.jpBlogSection h3, .jpBlogSection h2 {
    margin-top: 45px;
    color: #85a2ad;
    width: 90%;
}

.jpBlogItem h4, .jpBlogItem h3 {
    margin-top: 45px;
    font-weight: 700;
    color: #413e40;
}

.jpBlogItem h4 {
    font-size: 20px;
    text-transform: none !important;
}

.jpBlogItem h3 {
    font-size: 18px;
}

.jpBlogItem p {
    font-size: 18px;
    letter-spacing: 0;
}

.jpBlogLeader p {
    font-size: 20px !important;
    font-weight: 700;
}

.jpBlogLeader {
    padding-top: 45px;
}

.jpBlogItem p a, .jpBlogItem p a:visited, .jpBlogItem h4 a, .jpBlogItem h4 a:visited {
    text-decoration: none;
    color: #85a2ad !important;
}

.jpBlogSection li {
    text-align: left;
}

.jpBlogItem li a {
    text-decoration: none;
    color: #85a2ad;
}

.jpBlogSection {
    /*margin-top: 60px;*/
    padding-bottom: 60px;
    border-bottom: 1px solid;
}

.jpBlogContainer {
    background: #f8f8f8;
    align-items: center;
}

.jpBlogContent {
    width: 95%;
    margin: auto;
    padding: 30px;
}

@media (min-width: 1200px) {
    .jpBlogContent {
        background: #f8f8f8;
        width: 80%;
        padding: 30px;
        margin: auto;
        align-items: center !important;
    }
}

@media (max-width: 400px) {
    .jpBlogContent {
        background: #FFF;
        width: 100%;
        padding: 15px;
        /*margin: auto;*/
        align-items: center !important;
    }
}

@media (min-width: 450px) {
    .blogLeftImg {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
    }
}

@media (min-width: 450px) {
    .blogLeftImg p, .blogLeftImg h4, .blogLeftImg h3 {
        width: 75%;
        padding-left: 30px;
    }
}

.jpBlogImageLeft {
    width: 150px;
}

.jpBlogImageLeft img {
    width: 100%;
    height: auto;
    border-radius: 15px;

}

.jpBlogImageLeftLarge img {
    width: 250px;
    height: auto;
    border-radius: 15px;

}

.blogLeftImg h4, .blogLeftImg h3 {
    margin-top: 0px !important;
}

@media (min-width: 900px) {
    .jpBlogsItem {
        display: flex;
        /*background: #85a2ad;*/
        align-items: center;
        width: 100%;
        height: 400px;
    }
}

.jpBlogsItem {
    margin-bottom: 45px;
}

.jpBlogsItem h2, .jpBlogsItem p {
    text-align: left;
    color: #FFF;
}

.jpBlogsItem p {
    font-size: 18px;
    /*padding: 0px 45px 0px 0px;*/
}

.jpBlogsItem p a, .jpBlogsItem p a:visited, .jpBlogsItem h2 a, .jpBlogsItem h2 a:visited {
    text-decoration: none !important;
    color: #FFF;

}

.jpBlogsItemTextCont {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #85a2ad;
    /*padding: 0px 30px 30px 30px;*/
    width: 100%;
    height: 100%;
}

.jpBlogsItemText {
    padding: 0px 30px 0px 30px;
}

@media (max-width: 900px) {
    .jpBlogsItemText {
        padding: 30px 30px 30px 30px;
    }
}

.jpBlogsItemImageCont {
    width: 100%;
    height: 100%;
    height: inherit;
    background: #85a2ad;
}

.jpBlogsItem img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: #85a2ad;
}

.jpBlogPricing {
    font-weight: bold;
}

.jpBlogProduct {
    margin-bottom: 45px;
}


/*.jpBlogProductInfo h4{*/
/*    width: 500px;*/
/*    background: red;*/
/*}*/

/*.jpBlogSection h3*/
/*  {*/
/*   text-align: left;*/
/*    font-style: bold;*/
/*    color: #413e40;*/
/*}*/

.preloader {
    /*background: red;*/
    height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.preloader img {
    width: 100px;
}

/* NEW MOBILE H1 STYLES TO BIN BALANCETEXT */
.mobile-title {
    white-space: normal;
    word-break: normal;
    overflow-wrap: normal;
    text-wrap: wrap;
    max-width: 520px;
}


/*.balancedText br {*/
/*    content: "";*/
/*}*/
/*.balancedText br::after {*/
/*     content: " \A"; !* Line break *!*/
/*    white-space: pre; !* Ensure the line break is respected *!*/

/*}*/


/* USER BLOCK ON CREATE PAGE*/
.user-info {
    margin-bottom: 30px;
    font-size: 16px;
}

.user-info strong {
    margin-right: 15px; /* Adjust space as needed */
}

.leftText {
    padding-left: 16px;
    text-align: left;
}

/*FAQ ACCORDION*/
/*.accordionTop {*/
/*    margin-top: 5px;*/
/*    !*padding: 20px 120px 20px 120px;*!*/
/*    padding: 20px 10% 20px 10%;*/
/*    !*background: #85a2ad;*!*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    justify-content: space-between;*/
/*    align-items: center;*/
/*    cursor: pointer;*/
/*}*/
.accordionTop {
    margin-top: 5px;
    padding: 20px 10% 20px 10%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.accordionCenter {
    /*justify-content: center;*/
    /*align-items: space-between;*/
    margin-top: 0px;
    width: 50%;
}
@media (max-width: 1200px) {
    .accordionCenter {
            width: 100%;

    }
}

.accordionButton {
    color: #FFF;
    height: 60px;
    font-size: 40px;
    cursor: pointer;
}

/*.accordionContent {*/
/*    overflow: hidden; !* Ensures content doesn't spill out *!*/
/*    !*transition: max-height 13s ease-out; !* Smooth transition effect *!*!*/
/*    text-align: left;*/
/*    !*padding: 0px 120px 0px 120px;*!*/
/*    padding: 0% 10% 0% 10%;*/
/*    max-height: 0px;*/
/*    transition: max-height 0.3s ease-out;*/
/*}*/

/*.accordionContent.open {*/
/*    max-height: 1000px; !* Large enough to accommodate all content *!*/
/*}*/



.accordionContent {
    padding: 0% 10% 0% 10%;
    /*text-align: left;*/
    transition: max-height 0.3s ease-out;
}

.accordionContent.closed {
    overflow: hidden;
    max-height: 0px; /* Set the height for the collapsed state */
}
.accordionContent.open {
    max-height: 3500px; /* Removes the height limit when open */
    transition: max-height 0.3s ease-in-out; /* Smooth transition to the open state */
}




@media (min-width: 1800px) {
    .accordionContent p, .accordionContent h3 {
        max-width: 75%;
    }
}
@media (max-width: 1200px) {
    .accordionContent, .accordionTop {
        /*padding: 20px 5% 20px 5%;*/
    }
}
@media (max-width: 900px) {
    .accordionContent, .accordionTop {
        /*padding: 30px;*/
    }
}

.accordionContent h3 {
    text-transform: none;
}

/*.accordionContent.open {*/
/*    max-height: 500px; !* Set a high enough value to accommodate the content *!*/
/*}*/

.accordionSection {
    margin: 0px 0px 30px 0px;
}

